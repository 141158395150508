import { render, staticRenderFns } from "./BalanceKeyFiguresView.vue?vue&type=template&id=2e66ae10"
import script from "./BalanceKeyFiguresView.vue?vue&type=script&setup=true&lang=ts"
export * from "./BalanceKeyFiguresView.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {WidgetKeyValue: require('/app/components/Widget/WidgetKeyValue.vue').default})
