import { render, staticRenderFns } from "./Legend.vue?vue&type=template&id=52841f30"
import script from "./Legend.vue?vue&type=script&setup=true&lang=ts"
export * from "./Legend.vue?vue&type=script&setup=true&lang=ts"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {KeyValuePairContainerRow: require('/app/node_modules/@align/ui/dist/runtime/molecules/KeyValuePair/KeyValuePairContainerRow.vue').default})
