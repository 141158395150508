import { UIColor } from "~/helpers/common-helpers";

export const COLOR_SHADES = [1000, 200, 800, 400, 600] as const;

export const getColorHex = (cssVariable: string) => {
  return getComputedStyle(document.documentElement).getPropertyValue(cssVariable).replace(" ", "");
};

export const getColorsHex = () => {
  const hexColors = COLOR_SHADES.map((shade) => getColorHex(`--color-accent-${shade}`));

  return hexColors;
};

export const getColorShadesRepeating = (n: number) => {
  const a: UIColor[] = [];

  let i = 0;

  for (let j = 0; j < n; j++) {
    if (i >= COLOR_SHADES.length) {
      i = 0;
    }

    a.push(`--color-accent-${COLOR_SHADES[i]}`);

    i++;
  }

  return a;
};

export const colorForegroundForColor = (color: UIColor) => {
  const colorValue = parseInt(color.replace("--color-accent-", ""), 10);

  if (colorValue > 600) {
    return "--color-white";
  }

  return "--color-primary";
};
